<template>
  <el-card v-loading="isLoading" shadow>
    <el-descriptions title="Dados da Licitação" class="margin-top">
      <template #extra>
        <el-button-group>
          <el-button
            onlyIcon
            type="primary"
            v-if="!showEditPublicSellForm"
            :disabled="false"
            @click="toggleEditPublicSellForm(true)"
          >
            <i class="el-icon-edit"></i>
          </el-button>
          <el-button v-else type="primary" @click="save"> salvar </el-button>
          <el-popconfirm
            v-if="!isNew && canAny('DeletePublicSell')"
            @confirm="deletePublicSell()"
            title="
              Remover licitação?
            "
            ><template #reference>
              <el-button onlyIcon type="danger" circle>
                <i class="el-icon-delete"></i></el-button></template
          ></el-popconfirm>
        </el-button-group>
      </template>
      <template #default></template>
    </el-descriptions>
    <base-form :disabled="!showEditPublicSellForm">
      <el-descriptions border :column="1">
        <template>
          <el-descriptions-item :span="8">
            <template #label>Nome</template>
            <base-input v-model="publicSell.name"></base-input>
          </el-descriptions-item>
          <el-descriptions-item :span="4">
            <template #label> Empresa </template>
            <el-select
              v-model="publicSell.firm_id"
              size="medium"
              cleareable
              :disabled="!showEditPublicSellForm"
            >
              <el-option
                v-for="item in Firms"
                :key="item.uid"
                :label="item.name"
                :value="item.uid"
              >
              </el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item :span="4">
            <template #label> Cliente </template>
            <el-select
              v-model="publicSell.partner_id"
              size="medium"
              filterable
              :filter-method="searchPartner"
              cleareable
            >
              <el-option
                v-for="item in Partners"
                :key="item.uid"
                :label="item.name"
                :value="item.uid"
              >
              </el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item>
            <el-row type="flex" justify="end">
              <el-radio-group
                :model-value="publicSellStatus[publicSell.status]"
                @update:model-value="updatePublicSellStatus($event)"
                size="large"
              >
                <el-radio-button label="Ativa" />
                <el-radio-button label="Cancelada" />
              </el-radio-group>
            </el-row>
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> CNPJ </template>
            {{ Partner?.legal?.cnpj }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Razão Social </template>
            {{ Partner?.legal?.social_name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Inscrição Estadual </template>
            {{ Partner?.legal?.state_registry }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Endereço </template>
            {{ Partner?.addr?.extended }}
          </el-descriptions-item>
          <el-descriptions-item :span="3">
            <template #label> Data de Início </template>
            <base-date-picker v-model="publicSell.start_at" />
          </el-descriptions-item>
          <el-descriptions-item :span="3">
            <template #label> Data de Encerramento </template>
            <base-date-picker v-model="publicSell.end_at" />
          </el-descriptions-item>
          <el-descriptions-item :span="2">
            <template #label> CST</template>
            <base-select v-model="publicSell.cst">
              <base-select-option
                v-for="c of CST"
                :key="c.label"
                :label="c.label"
                :value="c.value"
              ></base-select-option>
            </base-select>
          </el-descriptions-item>
          <el-descriptions-item label="Casas decimais">
            <base-input v-model="publicSell.decimals"></base-input>
          </el-descriptions-item>
          <el-descriptions-item label="Subgrupos">
            <base-select
              v-model="subgroups"
              @change="changeSubgroups"
              allow-create
              multiple
              filterable
            >
              <el-option
                v-for="s in Subgroups"
                :key="'Subgroups' + s"
                :label="s"
                :value="s"
              >
              </el-option>
            </base-select>
          </el-descriptions-item>
        </template> </el-descriptions
    ></base-form>

    <el-descriptions
      class="margin-top"
      title="Produtos"
      :column="3"
      border
      v-if="!isNew"
    >
      <template #extra>
        <el-button type="primary" @click="openProductPublicSellModal"
          >adicionar</el-button
        >
      </template>
      <template>
        <el-descriptions-item>
          <el-table
            stripe
            :data="PublicSellProducts"
            style="width: 100%; z-index: 0"
            :header-style="
              () => 'text-align:center;padding-left: 2px;padding-right:2px;'
            "
            :cell-style="
              () => 'text-align:center;padding-left: 2px;padding-right:2px;'
            "
          >
            <el-table-column label="produto" prop="product.name" />
            <el-table-column label="nome na nota">
              <template #default="{ row: product }">
                <base-input
                  v-model="product.brand"
                  v-on:keyup.enter="
                    updatePublicSellProduct({
                      uid: product.uid,
                      brand: product.brand,
                    })
                  "
                ></base-input
              ></template>
            </el-table-column>
            <el-table-column label="corte" prop="mounted.cutting" />
            <el-table-column label="valor kg" prop="amount">
              <template #default="{ row: product }">
                <base-input
                  v-model="product.amount"
                  label="R$"
                  type="money"
                  v-on:keyup.enter="
                    updatePublicSellProduct({
                      uid: product.uid,
                      amount: product.amount,
                    })
                  "
                ></base-input
              ></template>
            </el-table-column>
            <el-table-column label="peso máximo" prop="total_weight">
              <template #default="{ row: product }">
                <base-input
                  v-model="product.total_weight"
                  type="money"
                  v-on:keyup.enter="
                    updatePublicSellProduct({
                      uid: product.uid,
                      total_weight: product.total_weight,
                    })
                  "
                >
                  <template #append>Kg.</template>
                </base-input>
              </template>
            </el-table-column>
            <el-table-column label="armazenamento" prop="product.storage">
              <template #default="{ row: product }">
                <el-select
                  v-model="product.storage"
                  @change="
                    updatePublicSellProduct({
                      uid: product.uid,
                      storage: product.storage,
                    })
                  "
                  size="medium"
                >
                  <el-option
                    v-for="item in StorageProperties"
                    :key="item"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="obs" prop="obs">
              <template #default="{ row: product }">
                <base-input
                  v-model="product.obs"
                  v-on:keyup.enter="
                    updatePublicSellProduct({
                      uid: product.uid,
                      obs: product.obs,
                    })
                  "
                ></base-input
              ></template>
            </el-table-column>
            <el-table-column
              label="total vendido"
              :formatter="
                (row) =>
                  weightFormatter.format(calcTotalSold(publicSell, row)) +
                  ' kg.'
              "
            />
            <el-table-column label="ações">
              <template #default="{ row: product }">
                <el-row type="flex" justify="end">
                  <base-button
                    :onClick="() => deleteProduct(product)"
                    onlyIcon
                    title="Remover produto"
                    icon="el-icon-delete"
                    type="danger"
                  ></base-button></el-row
              ></template>
            </el-table-column>
          </el-table>
        </el-descriptions-item>
      </template>
    </el-descriptions>

    <el-descriptions
      class="margin-top"
      title="Empenhos"
      :column="3"
      border
      v-if="!isNew"
    >
      <template #extra>
        <el-space justify="space-between" :size="4">
          <el-button type="primary" @click="createPublicSellReservation"
            >adicionar</el-button
          >
        </el-space>
      </template>
      <template>
        <el-descriptions-item>
          <el-table
            stripe
            :data="PublicSellReservations"
            row-key="uid"
            style="width: 100%; z-index: 0"
            :header-style="
              () => 'text-align:center;padding-left: 2px;padding-right:2px;'
            "
            :cell-style="
              () => 'text-align:center;padding-left: 2px;padding-right:2px;'
            "
          >
            <el-table-column type="expand">
              <template #default="{ row: reservation }">
                <el-table stripe :data="reservation.quantities">
                  <el-table-column
                    label="nome"
                    prop="related.product.name"
                  ></el-table-column>
                  <el-table-column
                    label="valor"
                    prop="related.amount"
                    :formatter="
                      ({ related }) =>
                        currencyFormatter.format(Number(related?.amount))
                    "
                  ></el-table-column>
                  <el-table-column
                    label="vendido"
                    :formatter="
                      (row) =>
                        weightFormatter.format(
                          Number(calcTotalSold(reservation, row))
                        ) + ' kg.'
                    "
                  ></el-table-column>
                  <el-table-column
                    label="restante"
                    :formatter="
                      (row) =>
                        weightFormatter.format(
                          Number(
                            (Number(row.max_weight) -
                              calcTotalSold(reservation, row)) /
                              Number(row.max_weight)
                          ) * 100 || 0
                        ) + ' %'
                    "
                  ></el-table-column>
                  <el-table-column label="quantidade">
                    <template #default="{ row: quantity }">
                      <base-input
                        :disabled="reservation.status === 'canceled'"
                        v-model="quantity.max_weight"
                        type="money"
                        :minimumFractionDigits="3"
                        v-on:keyup.enter="
                          updateResourceReservation({
                            uid: reservation.uid,
                            quantities: [
                              {
                                uid: quantity.uid,
                                public_sell_product_id:
                                  quantity.public_sell_product_id,
                                max_weight: quantity.max_weight,
                              },
                            ],
                          })
                        "
                      >
                        <template #append>Kg.</template>
                      </base-input>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column label="identificador" prop="name">
              <template #default="{ row: resourceReservation }">
                <base-input
                  v-model="resourceReservation.name"
                  v-on:keyup.enter="
                    updateResourceReservation({
                      uid: resourceReservation.uid,
                      name: resourceReservation.name,
                    })
                  "
                ></base-input
              ></template>
            </el-table-column>
            <el-table-column label="total de itens" prop="quantities.length">
            </el-table-column>
            <el-table-column
              label="itens finalizados"
              :formatter="(row) => calcCompletedProducts(row)"
            >
            </el-table-column>
            <el-table-column label="vendido" prop="cutting" />
            <el-table-column label="total vendido" prop="total_sold" />
            <el-table-column label="ações">
              <template #default="{ row: reservation }">
                <el-row type="flex" justify="end" :gutter="4">
                  <base-dropdown
                    :labels="['Ativo', 'Inativo', 'Cancelado']"
                    class="mr-1"
                    :selectedCommand="reservation.status"
                    :disabled="reservation.status === 'canceled'"
                    :type="
                      {
                        active: 'primary',
                        inactive: 'warning',
                        canceled: 'danger',
                      }[reservation.status]
                    "
                    :commands="['active', 'inactive', 'canceled']"
                    @command="
                      (status) =>
                        updateResourceReservation({
                          uid: reservation.uid,
                          status,
                        })
                    "
                  ></base-dropdown>
                  <base-button
                    :onClick="() => deleteResourceReservation(reservation)"
                    onlyIcon
                    title="Remover empenho"
                    icon="el-icon-delete"
                    type="danger"
                  ></base-button>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
        </el-descriptions-item>
      </template>
    </el-descriptions>

    <product-public-sell
      :showModal="showProductPublicSellModal"
      @on-add-product="addProductToPublicSell"
      @close-modal="showProductPublicSellModal = false"
    ></product-public-sell>
  </el-card>
</template>
<script>
import PublicSellService from "../services/public_sells";
import PartnerService from "../services/partners";
import {
  dateFormatter,
  currencyFormatter,
  weightFormatter,
} from "../utils/formatters";
import { notifySuccess, notifyError, promptOnCenter } from "../utils/notifiers";
import FirmService from "../services/firms";
import { isUuid } from "../utils/functions";
import _ from "lodash";
import ProductPublicSell from "./modals/ProductPublicSell.vue";
export default {
  name: "PublicSellDetails",
  components: {
    ProductPublicSell,
  },
  data() {
    return {
      isLoading: false,
      isLoadingCNPJ: false,
      showProductPublicSellModal: false,
      partners: null,
      publicSell: {},
      subgroups: null,
      subgroupSuggestions: null,
      showEditPublicSellForm: false,
      firm_bank_accounts: null,
      negotiationType: "sells",
      showNegotiationsModal: false,
      negotiations: [],
      oldAccountIds: [],
      showProviderPricesModal: false,
      showImageViewer: false,
      currencyFormatter,
      weightFormatter,
      dateFormatter,
      cnpj: {},
      publicSellStatus: {
        active: "Ativa",
        inactive: "Cancelada",
      },
      expandResourceReservationRows: [],
      firms: null,
    };
  },
  watch: {
    publicSell(publicSell) {
      if (publicSell.firm) this.publicSell.firm_id = publicSell.firm.uid;
      if (publicSell.partner) {
        this.publicSell.partner_id = publicSell.partner.uid;
        this.partners = [this.publicSell.partner];
      }
      if (publicSell.subgroups) {
        this.subgroups = publicSell.subgroups.map((s) => s.name);
      }
    },
  },
  mounted() {
    if (!this.isNew) this.loadPublicSellData();
    else this.showEditPublicSellForm = true;

    this.loadFirms();
    this.loadGroups();
  },
  methods: {
    updatePublicSellStatus(status) {
      const publicSellStatus = {
        Cancelada: "inactive",
        Ativa: "active",
      };
      this.publicSell.status = publicSellStatus[status];
    },
    calcCompletedProducts(reservation) {
      return reservation?.quantities
        ?.map(
          (product) =>
            product.max_weight - this.calcTotalSold(reservation, product)
        )
        ?.filter((totalRemaining) => totalRemaining <= 0)?.length;
    },
    async createPublicSellReservation() {
      const { value: name } = await promptOnCenter("Insira o nome do empenho", {
        confirmButtonText: "Salvar",
        cancelButtonText: "Cancelar",
      });

      if (name) {
        const { reservation } = await PublicSellService(this.publicSell.uid)
          .Reservations()
          .create({
            name,
          });

        if (reservation) {
          notifySuccess(reservation.message);
          this.loadPublicSellData();
        }
      }
    },
    async updateResourceReservation(_) {
      const { reservation } = await PublicSellService(this.publicSell.uid)
        .Reservations(_.uid)
        .update(_);

      if (reservation) {
        notifySuccess("Empenho atualizado com sucesso");
        this.loadPublicSellData();
      }
    },
    async deleteResourceReservation(_) {
      const { reservation } = await PublicSellService(this.publicSell.uid)
        .Reservations(_.uid)
        .delete();

      if (reservation) {
        notifySuccess("Empenho removido com sucesso");
        this.loadPublicSellData();
      }
    },
    changeSubgroups(v) {
      const self = this;
      const subGroupsToRemove = this.publicSell.subgroups?.filter(
        (s) => !v.includes(s.name)
      );
      const subgroupsToAdd = v.filter(
        (s) => !self.publicSell.subgroups?.some(({ name }) => name === s)
      );

      subGroupsToRemove?.forEach((sub) => this.deleteSubgroup(sub.uid));
      subgroupsToAdd?.forEach((sub) => this.addSubgroup(sub));
    },
    async addSubgroup(name) {
      await PublicSellService(this.publicSell.uid).Subgroups().create({ name });
    },
    async deleteSubgroup(uid) {
      await PublicSellService(this.publicSell.uid).Subgroups(uid).delete();
    },
    async addProductToPublicSell(_product) {
      const { product } = await PublicSellService(this.publicSell.uid)
        .Products()
        .create(_product);

      if (product) {
        notifySuccess("Produto adicionado com sucesso");
        this.loadPublicSellData();
      }
    },
    calcTotalSold(resourceReservation, product) {
      if (!resourceReservation?.orders?.length) return 0;

      const productMountedId =
        product?.related?.mounted?.id || product?.mounted?.id;

      return resourceReservation.orders
        .map(({ products }) => products)
        .flat()
        .filter((product) => product.mounted.id === productMountedId)
        .reduce((total, product) => total + Number(product.weight), 0);
    },
    openProductPublicSellModal() {
      this.showProductPublicSellModal = true;
    },
    async loadGroups() {
      const { publicSell: subgroups } = await PublicSellService(
        "subgroups"
      ).get();

      if (subgroups) this.subgroupSuggestions = subgroups;
    },
    async searchPartner(searchName) {
      const { partners } = await PartnerService().index({ searchName });

      if (partners) this.partners = partners;
    },
    async deleteProduct(_) {
      const { product } = await PublicSellService(this.publicSell.uid)
        .Products(_.uid)
        .delete();

      if (product) {
        notifySuccess(product.message);
        this.loadPublicSellData();
      }
    },
    async updatePublicSellProduct(_) {
      const { product } = await PublicSellService(this.publicSell.uid)
        .Products(_.uid)
        .update(_);

      if (product) notifySuccess("Produto atualizado");
    },
    async save() {
      if (this.isNew) this.createPublicSell();
      else this.updatePublicSell();

      this.showEditPublicSellForm = false;
    },
    async createPublicSell() {
      const { publicSell } = await PublicSellService().create(this.publicSell);

      if (publicSell) {
        notifySuccess("Licitação cadastrada com sucesso");
        const routePusher = this.$router.push;
        setTimeout(() => routePusher(`/licitacoes/${publicSell.uid}`), 500);
      } else notifyError("Ocorreu um erro");
    },
    async updatePublicSell() {
      const { publicSell } = await PublicSellService(
        this.publicSell.uid
      ).update(this.publicSell);

      if (publicSell) notifySuccess("Licitação atualizada com sucesso");
    },
    toggleEditPublicSellForm(showForm) {
      this.showEditPublicSellForm = showForm;
    },
    canAny() {
      for (let a in arguments) if (this.can(arguments[a])) return true;

      return false;
    },
    async fetchSubgroupSuggestions() {},
    async loadFirms() {
      const { firms } = await FirmService().index();

      if (firms) this.firms = firms;
    },
    filterPaymentDays(v) {
      return [
        {
          label: `${v} dias`,
          value: v,
        },
      ];
    },
    async deletePublicSell() {
      const { publicSell, error } = await PublicSellService(
        this.publicSell.uid
      ).delete();

      if (publicSell) {
        notifySuccess("Licitação removido com sucesso");
        const router = this.$router;
        setTimeout(() => {
          router.push("/licitacoes");
        }, 500);
      } else notifyError("Não foi possível remover a licitação", error.message);
    },
    async loadPublicSellData() {
      const { publicSell } = await PublicSellService(
        this.$route.params.publicSellId
      ).get();

      if (publicSell) {
        this.publicSell = publicSell;

        this.oldAccountIds = publicSell.bank_accounts?.map((a) => a.uid);
      }

      this.isLoading = false;
    },
  },
  computed: {
    Subgroups() {
      return this.subgroupSuggestions || [];
    },
    StorageProperties() {
      return [
        { name: "Resfriado", value: "cold" },
        { name: "Congelado", value: "freezed" },
      ];
    },
    TotalSoldByResourceReservation() {
      return [];
    },
    MaxQuantities() {
      return [];
    },
    Partners() {
      return this.partners || [];
    },
    CST() {
      return [
        {
          label: "20: Com redução da BC",
          value: 20,
        },
        {
          label: "40: Isenta",
          value: 40,
        },
        {
          label: "41: Não tributada",
          value: 41,
        },
      ];
    },
    can() {
      return this.$store.state.auth.can;
    },
    Partner() {
      return this.Partners.find((p) => p.uid === this.publicSell.partner_id);
    },
    isNew() {
      return !isUuid(this.$route.params.publicSellId);
    },
    Firms() {
      return this.firms || [];
    },
    PublicSell() {
      return this.publicSell || {};
    },
    PublicSellProducts() {
      return this.PublicSell?.products || [];
    },
    PublicSellReservations() {
      const reservations = _.cloneDeep(this.PublicSell?.reservations);

      if (reservations) {
        for (let reservation of reservations) {
          const quantities = reservation.quantities;

          quantities.push(
            ...this.PublicSellProducts.filter(
              (publicSellProduct) =>
                !quantities.some(
                  ({ related }) =>
                    publicSellProduct?.mounted?.id === related?.mounted?.id
                )
            ).map((related) => ({
              public_sell_product_id: related.uid,
              max_weight: 0,
              related,
            }))
          );
        }

        return reservations;
      }

      return [];
    },
  },
};
</script>
<style>
.margin-top {
  margin-top: 15px;
}

.danger {
  color: red;
}
</style>
